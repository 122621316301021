:root{
    --dark-green-color: #011d1e;
    --primary-color: #0f7477;
    --secondary-color: #0a4f51;
    --third-color: #579395;
    --primary-background-color: #B6D4D5;
    --color-light: #f2f2f2;
    --background-color-light: #d0d1d1c8;
    --color-dark: #1a1a1a;
    --color-red: #690000;
    --color-red-light: #69000077;
    --color-red-vibrant: #b80909;
}
*{
    font-family: Arial, Helvetica, sans-serif;
    background-color: var(--color-light);
    color: var(--color-dark);
}