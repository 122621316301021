.Loader-page{
  display: grid;
  place-content: center;
  position: fixed;
  background-color: rgba(204, 204, 204, 0.875);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  overflow: hidden;
}
.Loader {
    width: 48px;
    height: 48px;
    border: 3px solid #0a4f51;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .Loader::after {
      content: '';  
    box-sizing: border-box;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-bottom-color: #0f7477;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 