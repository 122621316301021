.login-page {
  position: static;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color-light);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.login-box{
    min-height: 500px;
    min-width: 300px;
    border: solid 1px var(--primary-color);
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}
