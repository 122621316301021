@import url("../Config/config.css");
.eyeIconOpen, .eyeIconClosed{
    font-size: 1.5rem;
    fill: var(--primary-color);
    position: absolute;
    right: -15px; 
    top: 43%; 
    cursor: pointer;
}

.add-service-icon, .add-product-icon{
    min-height: 70px;
    min-width: 70px;
    fill: var(--primary-color);
}

.offer-list-icon{
    fill: var(--primary-color);
    min-width: 20px;
    min-height: 20px;
}

.close-icon{
    position: absolute;
    top: 1rem;
    right: 1rem;
    stroke: var(--primary-color);
    stroke-width: 2px;
    cursor: pointer;
}

.copy-icon{
    fill: var(--primary-color);
    font-size: 1.25rem;
    cursor: pointer;
}