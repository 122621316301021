.add-company-box{
    height: 99vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
}
.company-data{
    border: var(--third-color) solid 2px;
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.company-data > *{
    font-size: 1rem;
    color: var(--dark-green-color);
}
.title{
    font-size: 1.25rem;
    color: var(--primary-color);
    font-weight: bolder;
}

.input-fields{
    width: 100%;
    height: 50%;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    flex-direction: column;
}
.button-box{
    width: 30%;
    
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
    .company-data{
        height: 40%;
        display: flex;
        flex-direction: column;
    }
    .button-box{
        width: 100%;
    }
}