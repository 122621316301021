@import url('../Config/config.css');

.input-box {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 50%;
  }
  
  .input-box label.label {
    z-index: 1;
    font-size: 0.90rem;
    color: var(--primary-color);
    font-weight: 700;
    position: relative;
    top: 0.5rem;
    margin-left: 7px;
    padding: 0 3px;
    background: var(--color-light);
    width: fit-content;
  }

  .input-box input[type=text].input-bar, input[type=password].input-password, input[type=text].input-password{
    width: 100%;
    padding: 11px 10px;
    font-size: 0.90rem;
    border: 2px var(--primary-color) solid;
    border-radius: 5px;
    background: var(--color-light);
  }
  
  .input-box input[type=text].input-bar:focus, input[type=password].input-password:focus, input[type=text].input-password:focus{
    outline: none;
  }

  .input-container{ 
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }
  .input-container-center{
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-container-left{
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0 0.5rem;
  }

  .input-container-multiple{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30%;
    max-width: 50%;
  }

  .input-wrapper{
    position: relative;
    width: 100%;
  }