.button-1 {
    appearance: button;
    background-color: var(--third-color);
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    line-height: 1.15;
    overflow: visible;
    font-size: 13px;
    padding: 7px 14px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: all 80ms ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
  }
  
  .button-1:disabled {
    opacity: .5;
  }
  
  .button-1:focus {
    outline: 0;
  }
  
  .button-1:hover {
    background-color: var(--primary-color);
    color: var(--color-light);
    border: 1px solid var(--secondary-color);
  }
  
  .button-1:active {
    background-color: var(--third-color);
    border: 1px solid var(--secondary-color);
  }

  .button-2 {
    appearance: button;
    background-color: var(--light-color);
    border: 1px solid var(--color-red);
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--color-red);
    cursor: pointer;
    line-height: 1.15;
    overflow: visible;
    font-size: 13px;
    padding: 7px 14px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: all 80ms ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
  }
  
  .button-2:disabled {
    opacity: .5;
  }
  
  .button-2:focus {
    outline: 0;
  }
  
  .button-2:hover {
    background-color: var(--color-red-light);
    color: var(--color-light);
    border: 1px solid var(--color-red);
  }
  
  .button-2:active {
    background-color: var(--light-color);
    border: 1px solid var(--color-red);
  }